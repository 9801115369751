<template>
  <v-navigation-drawer v-model="drawer" app clipped>
    <v-list dense>
      <v-list-item link to="/home">
        <v-list-item-action>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/post-reports">
        <v-list-item-action>
          <v-icon>mdi-alert</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Post Reports</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/upvote-queue">
        <v-list-item-action>
          <v-icon>mdi-chevron-up-circle</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>Upvote Queue</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'SideNav',
  data() {
    return {
      drawer: null,
    }
  },
  mounted() {
    this.$root.$on('toggle-drawer', () => {
      this.drawer = !this.drawer
    })
  },
}
</script>
