


























import Vue from 'vue'
import SideNav from '../components/navigation/SideNav.vue'

export default Vue.extend({
  name: 'Dashboard',
  components: {
    SideNav,
  },
  data() {
    return {
      appVersion: process.env.VUE_APP_VERSION,
    }
  },
  methods: {
    toggleDrawer() {
      this.$root.$emit('toggle-drawer')
    },
  },
})
